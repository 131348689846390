import React, { useState, useEffect } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { FilterSection } from './Filter_Section';
import { DataTable } from './components/Datatable';
import { Host } from '../../Config';

export function FilterNotifications() {
	const [permission, setPermission] = useState(false);
	const [data, setData] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [messageAllState, setMessageAllState] = useState(false);

	const [filters, setFilters] = useState(null);
	const [regions, setRegions] = useState(null);
	const [brands, setBrands] = useState(null);
	const [categories, setCategories] = useState(null);
	const [isFilterLoading, setIsFilterLoading] = useState(true);

	const headers = [
		{ title: '#', prop: 'id', isFilterable: true, isSortable: true },
		{ title: 'اسم المستخدم', prop: 'username', isFilterable: true, isSortable: true },
		{ title: 'رقم الهاتف', prop: 'phone', isFilterable: true, isSortable: true },
		{ title: 'بونص', prop: 'points', isFilterable: true, isSortable: true },
		{ title: 'المبلغ الإجمالي', prop: 'total_orders', isFilterable: true, isSortable: true },
	];

	useEffect(() => {
		fetchRegions();
		fetchBrands();
		fetchCategories(0);
		setIsFilterLoading(false);
	}, []);

	useEffect(() => {
		if (filters) {
			fetchData();
		}
	}, [filters]);

	const handleBrandChange = (brandId) => {
		fetchCategories(brandId);
	};

	const handleFilterChange = (newFilters) => {
		setFilters(newFilters);
	};

	const handleChangeFilters = () => {
		setFilters(null);
		setData(null);
	};

	return (
		<>
			<div className="pt-3 pb-3 pe-3 ps-3">
				<div className="p-3 card">
					<div className="card">
						<div className="card-body position-relative">
							{filters && (
								<div
									className="bg-opacity-75 position-absolute w-100 h-100 bg-light d-flex justify-content-center align-items-center"
									style={{ top: 0, left: 0, zIndex: 100 }}>
									<Button onClick={handleChangeFilters}>Change Filters</Button>
								</div>
							)}
							{isFilterLoading ? (
								<div className="position-absolute w-100 h-100 bg-light d-flex justify-content-center align-items-center">
									<Spinner
										animation="border"
										role="status">
										<span className="visually-hidden">Loading...</span>
									</Spinner>
								</div>
							) : (
								<FilterSection
									onFilterChange={handleFilterChange}
									isDisabled={!!filters}
									regions={regions}
									brands={brands}
									onBrandChange={handleBrandChange}
									categories={categories}
								/>
							)}
						</div>
					</div>
					{isLoading ? (
						<div className="my-3 d-flex justify-content-center">
							<Spinner
								animation="border"
								role="status">
								<span className="visually-hidden">Loading...</span>
							</Spinner>
						</div>
					) : (
						data && (
							<div className="card-body">
								<DataTable
									body={data}
									headers={headers}
									filters={filters}
									messageAllState={messageAllState}
									setMessageAllState={setMessageAllState}
								/>
							</div>
						)
					)}
				</div>
			</div>
			<Toaster position="top-center" />
		</>
	);

	// fetch Data based on filters
	async function fetchData() {
		setIsLoading(true);
		setData(null);

		const requestData = {
			route: 'filter_customers',
			filters,
		};

		if (!localStorage || !localStorage.getItem('token')) {
			setIsLoading(false);
			return;
		}
		const token = localStorage.getItem('token');

		try {
			const response = await fetch(Host, {
				body: JSON.stringify(requestData),
				headers: {
					Authorization: token,
					'Content-Type': 'application/json',
				},
				method: 'post',
				mode: 'cors',
			});

			const customers = await response.json();
			if (customers.status.code === 1) {
				setData(customers.data);
				setPermission(customers.permission);
			} else {
				setData([]);
			}
		} catch (error) {
			console.error('Error fetching data:', error);
			setData([]);
		} finally {
			setIsLoading(false);
		}
	}

	// fetch regions
	async function fetchRegions() {
		setRegions(null);
		const data = {
			route: 'fetch_regions',
		};

		if (!localStorage || !localStorage.getItem('token')) {
			return;
		}
		const token = localStorage.getItem('token');

		var response = await fetch(Host, {
			body: JSON.stringify(data),
			headers: {
				Authorization: token,
				'Content-Type': 'application/json',
			},
			method: 'post',
			mode: 'cors',
		});

		var brands = await response.json();
		if (brands.status.code === 1) {
			const newData = [{ value: 0, label: 'All Cities' }];
			brands.data.forEach((e) => {
				newData.push({ value: e.id, label: e.name });
			});
			setRegions(newData);
		} else {
			setRegions([]);
		}
	}

	// fetch brands
	async function fetchBrands() {
		const data = {
			route: 'fetch_brands',
		};

		if (!localStorage || !localStorage.getItem('token')) {
			return;
		}
		const token = localStorage.getItem('token');

		var response = await fetch(Host, {
			body: JSON.stringify(data),
			headers: {
				Authorization: token,
				'Content-Type': 'application/json',
			},
			method: 'post',
			mode: 'cors',
		});

		var categories = await response.json();
		if (categories.status.code === 1) {
			const newData = [{ value: 0, label: 'All Brands' }];
			categories.data.forEach((e) => {
				newData.push({ value: e.id, label: e.en_name });
			});
			setBrands(newData);
		} else {
			setBrands([]);
		}
	}

	// fetch categories
	async function fetchCategories(brandId) {
		const data = {
			route: 'filter_categories',
			brand: brandId,
		};

		if (!localStorage || !localStorage.getItem('token')) {
			return;
		}
		const token = localStorage.getItem('token');

		var response = await fetch(Host, {
			body: JSON.stringify(data),
			headers: {
				Authorization: token,
				'Content-Type': 'application/json',
			},
			method: 'post',
			mode: 'cors',
		});

		var categories = await response.json();
		if (categories.status.code === 1) {
			const newData = [{ value: 0, label: 'All Categories' }];
			categories.data.forEach((e) => {
				newData.push({ value: e.id, label: e.en_name });
			});
			setCategories(newData);
		} else {
			setCategories([]);
		}
	}
}
