import { Route, Routes } from 'react-router-dom';
import { Tags } from './pages/tags/Tags';
import { Brands } from './pages/brands/Brands';
import { Categories } from './pages/categories/Categories';
import { Subcategories } from './pages/subcategories/Subcategories';
import { Items } from './pages/items/Items';
import { Relations } from './pages/relations/Relations';
import { InnerRelations } from './pages/relations/InnerRelations';
import { InsertRelations } from './pages/relations/InsertRelation';
import { SubItems } from './pages/subitems/SubItems';
import { TagGroups } from './pages/taggroups/TagGroups';
import { Customers } from './pages/customers/Customers';
import { Promotions } from './pages/Promotions/promotions';
import { PromotionDetails } from './pages/Promotions/Details/PromotionDetails';
import { PendingOrders } from './pages/orders/PendingOrders';
import { ConfirmedOrders } from './pages/orders/ConfirmedOrderState';
import { CanceledOrders } from './pages/orders/CanceledOrderState';
import { ShippingOrders } from './pages/orders/ShippingOrderState';
import { CompletedOrders } from './pages/orders/CompletedOrderState';
import { OrderDetails } from './pages/orders/OrderDetails';
import { Dashboard } from './pages/Dashboard/Dashboard';
import { Suggetions } from './pages/suggetions/Suggetions';
import { Coupons } from './pages/customers/Coupons';
import { Settings } from './pages/settings/Settings';
import { Export } from './pages/export/Export';
import { SearchBarcode } from './pages/SearchBarcode/search_barcode';
import { ItemRules } from './pages/item_rules/ItemRules';
import { Regions } from './pages/locations/regions';
import { Cities } from './pages/locations/cities';
import { Showrooms } from './pages/showrooms/showrooms';
import { FilterNotifications } from './pages/filter_notifications/Filter_Notifications';

export function MainRouting() {
	return (
		<Routes>
			<Route
				path="/dashboard"
				element={<Dashboard />}
			/>
			<Route
				path="/brands"
				element={<Brands />}
			/>
			<Route
				path="/tags"
				element={<Tags />}
			/>
			<Route
				path="/tag_groups"
				element={<TagGroups />}
			/>
			<Route
				path="/categories"
				element={<Categories />}
			/>
			<Route
				path="/search_barcode"
				element={<SearchBarcode />}
			/>
			<Route
				path="/sub_categories"
				element={<Subcategories />}
			/>
			<Route
				path="/items"
				element={<Items />}
			/>
			<Route
				path="/item_rules"
				element={<ItemRules />}
			/>
			<Route
				path="/relations"
				element={<Relations />}
			/>
			<Route
				path="/innerrelations"
				element={<InnerRelations />}
			/>
			<Route
				path="/insertrelations"
				element={<InsertRelations />}
			/>
			<Route
				path="/subitem"
				element={<SubItems />}
			/>
			<Route
				path="/customers"
				element={<Customers />}
			/>
			<Route
				path="/promotions"
				element={<Promotions />}
			/>
			<Route
				path="/promotiondetails"
				element={<PromotionDetails />}
			/>
			<Route
				path="/pending_orders"
				element={<PendingOrders />}
			/>
			<Route
				path="/confirmed_orders"
				element={<ConfirmedOrders />}
			/>
			<Route
				path="/canceled_orders"
				element={<CanceledOrders />}
			/>
			<Route
				path="/shipping_orders"
				element={<ShippingOrders />}
			/>
			<Route
				path="/completed_orders"
				element={<CompletedOrders />}
			/>
			<Route
				path="/order_details"
				element={<OrderDetails />}
			/>
			<Route
				path="/suggetions"
				element={<Suggetions />}
			/>
			<Route
				path="/coupons"
				element={<Coupons />}
			/>
			<Route
				path="/settings"
				element={<Settings />}
			/>
			<Route
				path="/export"
				element={<Export />}
			/>
			<Route
				path="/regions"
				element={<Regions />}
			/>
			<Route
				path="/cities"
				element={<Cities />}
			/>
			<Route
				path="/showrooms"
				element={<Showrooms />}
			/>
			<Route
				path="/filter_notifications"
				element={<FilterNotifications />}
			/>
		</Routes>
	);
}
