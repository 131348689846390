import React from 'react';
import Select, { components } from 'react-select';

const customStyles = {
	control: (provided, state) => ({
		...provided,
		minHeight: '30px',
		height: '30px',
		fontSize: '0.875rem',
		borderRadius: '0.2rem',
		borderColor: state.isFocused ? '#86b7fe' : '#ced4da',
		boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(13, 110, 253, 0.25)' : 'none',
	}),
	valueContainer: (provided) => ({
		...provided,
		padding: '0 0.375rem',
	}),
	input: (provided) => ({
		...provided,
		margin: '0',
		padding: '0',
		zIndex: '10',
	}),
	indicatorsContainer: (provided) => ({
		...provided,
		height: '30px',
	}),
	dropdownIndicator: (provided) => ({
		...provided,
		padding: '0.25rem',
	}),
	clearIndicator: (provided) => ({
		...provided,
		padding: '0.25rem',
	}),
	singleValue: (provided) => ({
		...provided,
		color: '#495057',
	}),
	menu: (provided) => ({
		...provided,
		borderRadius: '0.2rem',
		boxShadow: '0 0.25rem 0.5rem rgba(0, 0, 0, 0.1)',
		zIndex: '10',
	}),
	option: (provided, state) => ({
		...provided,
		backgroundColor: state.isFocused ? '#e9ecef' : 'white',
		color: '#212529',
		zIndex: '10',
		padding: '0.375rem 0.75rem',
	}),
};

export const CustomSelect = ({ options, handleChange, defaultValue, ...props }) => {
	let obj = null;
	try {
		obj = options.find((o) => o.value == defaultValue);
	} catch {}
	return (
		<Select
			options={options}
			onChange={(v) => handleChange(v.value)}
			defaultValue={obj}
			styles={customStyles}
			{...props}
		/>
	);
};
