import { Modal, ModalBody, ModalFooter, ModalHeader } from 'react-bootstrap';

export function ButtonModal({
	title = 'Dialog',
	btnText = 'إرسال نسخة جديدة',
	btnText2 = 'إرسال النسخة القديمة',
	btnAction = () => {},
	btnAction2 = () => {},
	state,
	changeState,
	children,
}) {
	var toggle = () => changeState(!state);

	return (
		<>
			<Modal
				show={state}
				centered>
				<ModalHeader>{title}</ModalHeader>
				<ModalBody>{children}</ModalBody>
				<ModalFooter>
					<button
						className="btn btn-sm"
						onClick={toggle}>
						الغاء
					</button>
					<button
						className="btn btn-sm btn-dark"
						onClick={() => {
							btnAction();
							toggle();
						}}>
						{btnText}
					</button>
					{btnText2 && (
						<button
							className="btn btn-sm btn-dark"
							onClick={() => {
								btnAction2();
								toggle();
							}}>
							{btnText2}
						</button>
					)}
				</ModalFooter>
			</Modal>
		</>
	);
}
