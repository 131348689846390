import { useState } from 'react';
import toast from 'react-hot-toast';
import { ImageUploader } from '../../components/imageuploader/ImageUploader';
import { ButtonModal } from './components/ButtonModal';
import { Host } from '../../Config';

export function SendPromotionNotification({ state, changeState, item }) {
	const [arabic, setArabic] = useState('');
	const [kurdish, setKurdish] = useState('');
	const [english, setEnglish] = useState('');
	const [image, setImage] = useState(null);

	return (
		<ButtonModal
			title="ارسال اشعار"
			state={state}
			changeState={changeState}
			btnAction={() => {
				insert(arabic, kurdish, english, image, item.id, item.ar_name);
			}}
			btnAction2={() => {
				insert_old(arabic, kurdish, english, image, item.id, item.ar_name);
			}}>
			<div className="mb-3">
				<ImageUploader
					image={image}
					setImage={setImage}
				/>
			</div>
			<input
				type="text"
				className="form-control form-control-sm mb-3"
				value={arabic}
				placeholder="عربي"
				onChange={(e) => setArabic(e.target.value)}
			/>
			<input
				type="text"
				className="form-control form-control-sm mb-3"
				value={kurdish}
				placeholder="كردي"
				onChange={(e) => setKurdish(e.target.value)}
			/>
			<input
				type="text"
				className="form-control form-control-sm mb-3"
				value={english}
				placeholder="انكليزي"
				onChange={(e) => setEnglish(e.target.value)}
			/>
		</ButtonModal>
	);

	function insert(ar, kr, en, image, id, name) {
		sendNotification('send_promotion_notification', ar, kr, en, image, id, name);
	}

	function insert_old(ar, kr, en, image, id, name) {
		sendNotification('send_promotion_notification_old', ar, kr, en, image, id, name);
	}

	function sendNotification(route, ar, kr, en, image, id, name) {
		const data = {
			route,
			arabic: ar,
			kurdish: kr,
			english: en,
			image,
			id,
			name,
		};

		if (!localStorage || !localStorage.getItem('token')) {
			toast.error('Please log in to send notifications.');
			return;
		}

		const token = localStorage.getItem('token');
		const toastId = toast.loading(`Progress: ${0}%`); // Initial toast

		fetch(Host, {
			body: JSON.stringify(data),
			headers: {
				Authorization: token,
				'Content-Type': 'application/json',
			},
			method: 'POST',
			mode: 'cors',
		})
			.then((response) => {
				if (!response.body) {
					throw new Error('Response body is missing.');
				}

				const reader = response.body.getReader();
				const decoder = new TextDecoder('utf-8');
				let receivedData = '';

				const readStream = () => {
					reader
						.read()
						.then(({ done, value }) => {
							if (done) {
								console.log('Stream finished.');
								toast.success('تم إرسال الإشعارات بنجاح!', { id: toastId });
								return;
							}

							receivedData += decoder.decode(value, { stream: true });

							// Accumulate chunks and process complete JSON objects
							let jsonChunks = receivedData
								.split('..123456789..')
								.filter(Boolean)
								.map((row) => JSON.parse(row)); // Assuming each JSON is on a new line\

							const json = jsonChunks.pop(); // Keep incomplete chunk in buffer

							// Process complete chunks
							try {
								if (json.status && json.status.progress) {
									const newProgress = json.status.progress;
									console.log(`Progress: ${newProgress}%`); // Debugging progress
									toast.loading(`Progress: ${newProgress}%`, { id: toastId });
								}
							} catch (err) {
								console.error('Error parsing JSON:', err);
							}

							// Continue reading the stream
							readStream();
						})
						.catch((err) => {
							console.error('Stream read error:', err);
							toast.error('حدث خطأ ما.', { id: toastId });
						});
				};

				// Start reading the stream
				readStream();
			})
			.catch((err) => {
				console.error('Request error:', err);
				toast.error('حدث خطأ ما.', { id: toastId });
			});
	}
}
