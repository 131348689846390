import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { ButtonModal } from '../../components/modals/ButtonModal';
import { CustomSelect } from '../../components/select/CustomSelect';
import { ImageUploader } from '../../components/imageuploader/ImageUploader';
import { Host } from '../../Config';

export function NewSubItem({ state, changeState, refresh, tags, setTags, itemId }) {
	const [barcode, setBarcode] = useState('');
	const [color, setColor] = useState('');
	const [classValue, setClass] = useState('');
	const [discount1, setDiscount1] = useState('');
	const [discount2, setDiscount2] = useState('');
	const [discount3, setDiscount3] = useState('');
	const [point1, setPoint1] = useState('');
	const [point2, setPoint2] = useState('');
	const [point3, setPoint3] = useState('');

	const [image, setImage] = useState();
	const [tag, setTag] = useState();

	const [images, setImages] = useState([]);
	const [selectedTags, setSelectedTags] = useState([]);

	useEffect(() => {
		if (image) {
			images.push({
				hover: false,
				data: image,
				priority: 0,
			});
			setImages(images);
			setImage(null);
			console.log('hello');
		}
	}, [image, setImage]);

	return (
		<ButtonModal
			title="مادة جديدة"
			state={state}
			changeState={changeState}
			btnAction={() => {
				insert(barcode, color, classValue, discount1, discount2, discount3, point1, point2, point3, images, selectedTags, itemId);
			}}>
			<div className="mb-3">
				<ImageUploader
					image={image}
					setImage={setImage}
				/>
			</div>

			<div className="d-flex flex-wrap mb-3">
				{images.map((e) => {
					return e ? (
						<div>
							<div
								className="d-flex me-2"
								style={{ position: 'relative' }}
								onMouseEnter={() => {
									const i = images.findIndex((x) => x == e);
									images[i].hover = true;

									var imgs = [];
									images.forEach((img) => {
										imgs.push(img);
									});

									setImages(imgs);
								}}
								onMouseLeave={() => {
									const i = images.findIndex((x) => x == e);
									images[i].hover = false;

									var imgs = [];
									images.forEach((img) => {
										imgs.push(img);
									});

									setImages(imgs);
								}}>
								{e.hover ? (
									<></>
								) : (
									<img
										className=""
										src={e.data}
										style={{ width: '50px', height: '50px', borderRadius: '7px' }}></img>
								)}
								{e.hover ? (
									<button
										className="btn btn-danger"
										onClick={() => {
											const i = images.findIndex((x) => x == e);
											images[i].hover = false;

											var imgs = [];
											images.forEach((img) => {
												if (img !== e) {
													imgs.push(img);
												}
											});

											setImages(imgs);
										}}
										style={{ width: '50px', height: '50px' }}>
										<i className="bi bi-trash"></i>
									</button>
								) : (
									<></>
								)}
							</div>
							<input
								type="number"
								className="form-control form-control-sm ms-2 mt-2"
								style={{ width: '50px' }}
								min="0"
								value={e.priority}
								onChange={(f) => {
									const i = images.findIndex((x) => x == e);
									images[i].priority = f.target.value;

									var imgs = [];
									images.forEach((img) => {
										imgs.push(img);
									});

									setImages(imgs);
								}}
							/>
						</div>
					) : (
						<></>
					);
				})}
			</div>
			<div className="mb-3 d-flex">
				<div className="w-100 me-3">
					<CustomSelect
						options={tags}
						handleChange={setTag}
						defaultValue={tag}
					/>
				</div>
				<button
					className="btn btn-dark btn-sm"
					onClick={() => {
						if (tag) {
							const i = tags.findIndex((x) => x.value == tag);
							console.log(i);
							selectedTags.push({
								id: tags[i].value,
								label: tags[i].label,
								uniqe: Math.random(),
								hover: false,
							});
							setSelectedTags(selectedTags);

							var tags_ = [];
							tags.forEach((t) => {
								tags_.push(t);
							});

							setTags(tags_);
						}
					}}>
					اضافة
				</button>
			</div>

			<div className="d-flex flex-wrap">
				{selectedTags.map((tag) => {
					return (
						<div
							className="me-2 mb-2"
							onMouseEnter={() => {
								const i = selectedTags.findIndex((x) => x == tag);
								selectedTags[i].hover = true;

								var tags = [];
								selectedTags.forEach((tag) => {
									tags.push(tag);
								});
								setSelectedTags(tags);
							}}
							onMouseLeave={() => {
								const i = selectedTags.findIndex((x) => x == tag);
								selectedTags[i].hover = false;

								var tags = [];
								selectedTags.forEach((tag) => {
									tags.push(tag);
								});
								setSelectedTags(tags);
							}}>
							<button
								type="button"
								className="btn btn-sm btn-secondary"
								onClick={() => {
									const i = selectedTags.findIndex((x) => x == tag);
									selectedTags[i].hover = false;

									var tags_ = [];
									selectedTags.forEach((t) => {
										if (t !== tag) {
											tags_.push(t);
										}
									});

									setSelectedTags(tags_);
								}}>
								{tag.label}
								{tag.hover ? (
									<span className="badge badge-light">
										<i className="bi bi-x"></i>
									</span>
								) : (
									<></>
								)}
							</button>
						</div>
					);
				})}
			</div>

			<input
				type="text"
				className="form-control form-control-sm mb-3"
				placeholder="الباركود"
				onChange={(e) => {
					// Remove any non-alphanumeric characters
					const sanitizedValue = e.target.value.replace(/[^0-9a-zA-Z]/g, '');
					setBarcode(sanitizedValue);
				}}
				value={barcode} // Bind the value to ensure it's controlled
			/>

			<div className="d-flex mb-3">
				<input
					type="text"
					className="form-control form-control-sm me-3"
					placeholder="اللون"
					onChange={(e) => setColor(e.target.value)}
				/>
				<input
					type="text"
					className="form-control form-control-sm"
					placeholder="الكلاس"
					onChange={(e) => setClass(e.target.value)}
				/>
			</div>

			<div className="d-flex mb-3">
				<input
					type="number"
					className="form-control form-control-sm me-3"
					placeholder="1 الخصم"
					onChange={(e) => setDiscount1(e.target.value)}
				/>
				<input
					type="number"
					className="form-control form-control-sm me-3"
					placeholder="2 الخصم"
					onChange={(e) => setDiscount2(e.target.value)}
				/>
				<input
					type="number"
					className="form-control form-control-sm"
					placeholder="3 الخصم"
					onChange={(e) => setDiscount3(e.target.value)}
				/>
			</div>
			<div className="d-flex mb-3">
				<input
					type="number"
					className="form-control form-control-sm me-3"
					placeholder="1 بونص"
					onChange={(e) => setPoint1(e.target.value)}
				/>
				<input
					type="number"
					className="form-control form-control-sm me-3"
					placeholder="2 بونص"
					onChange={(e) => setPoint2(e.target.value)}
				/>
				<input
					type="number"
					className="form-control form-control-sm"
					placeholder="3 بونص"
					onChange={(e) => setPoint3(e.target.value)}
				/>
			</div>
		</ButtonModal>
	);

	function insert(barcode, color, classValue, discount1, discount2, discount3, point1, point2, point3, images, tags, item) {
		const data = {
			route: 'new_sub_item',
			color: color,
			barcode: barcode,
			class: classValue,
			discount1: discount1,
			discount2: discount2,
			discount3: discount3,
			point1: point1,
			point2: point2,
			point3: point3,
			images: images,
			tags: tags,
			item: item,
		};

		if (!barcode || barcode === '') {
			toast.error('ألرجاء كتابة الباكود');
			return;
		}
		if (!color || color === '') {
			toast.error('ألرجاء كتابة اللون');
			return;
		}
		if (!classValue || classValue === '') {
			toast.error('ألرجاء كتابة الكلاس');
			return;
		}
		if (!images || images === '') {
			toast.error('ألرجاء اضافة صور');
			return;
		}
		if (!tags || tags === '') {
			toast.error('ألرجاء اختيار التاكات');
			return;
		}

		console.log(JSON.stringify(data));
		if (!localStorage || !localStorage.getItem('token')) {
			return;
		}
		const token = localStorage.getItem('token');

		fetch(Host, {
			body: JSON.stringify(data),
			headers: {
				Authorization: token,
				'Content-Type': 'application/json',
			},
			method: 'post',
			mode: 'cors',
		})
			.then((response) => {
				response.json().then((json) => {
					if (json.status.code === 1) {
						toast.success('تم اضافة المادة');
						refresh();
					} else {
						toast.error('حذث خطاء ما');
					}
				});
			})
			.catch(() => {
				toast.error('حذث خطاء ما');
			});
	}
}
