import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Button, Dropdown, InputGroup, ToggleButton, ButtonGroup } from 'react-bootstrap';
import { CustomSelect } from './components/CustomSelect';

export const FilterSection = ({ onFilterChange, isDisabled, regions, brands, onBrandChange, categories }) => {
	const [filters, setFilters] = useState({
		onlineOrders: true,
		offlineOrders: false,
		active: 2,
		date: '',
		dateComparison: '>',
		dateStatus: false,
		total: 0,
		totalComparison: '>',
		totalStatus: false,
		bonusCredits: 0,
		bonusCreditsComparison: '>',
		bonusCreditsStatus: false,
		region: { value: 0, label: 'All Cities' },
		regionComparison: '=',
		regionStatus: false,
		brand: { value: 0, label: 'All Brands' },
		brandComparison: '=',
		brandStatus: false,
		category: { value: 0, label: 'All Categories' },
		categoryComparison: '=',
		categoryStatus: false,
		barcode: '',
		barcodeComparison: '=',
		barcodeStatus: false,
	});

	const comparisonDictionary = {
		'Greater Than': '>',
		'Less Than': '<',
		'Equal to': '=',
		'Not Equal to': '<>',
	};

	const handleInputChange = (event) => {
		const { name, value, type, checked } = event.target;
		setFilters((prevFilters) => {
			if (filters.barcodeStatus || filters.categoryStatus || filters.brandStatus || filters.dateStatus) {
				if (name === 'onlineOrders' && !checked) {
					if (!prevFilters.offlineOrders) {
						return prevFilters;
					}
				} else if (name === 'offlineOrders' && !checked) {
					if (!prevFilters.onlineOrders) {
						return prevFilters;
					}
				}
			}
			return {
				...prevFilters,
				[name]: type === 'checkbox' ? checked : value,
			};
		});
	};

	const handleComparisonChange = (eventKey, event, name) => {
		eventKey = comparisonDictionary[eventKey];
		setFilters((prevFilters) => ({
			...prevFilters,
			[`${name}Comparison`]: eventKey,
		}));
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		onFilterChange(filters);
		// console.log(filters);
	};

	const toggleFilter = (filterName) => {
		setFilters((prevFilters) => {
			const newStatus = !prevFilters[`${filterName}Status`];

			// Logic to handle mutual exclusivity between barcodeStatus and categoryStatus/brandStatus
			if (filterName === 'barcode') {
				return {
					...prevFilters,
					barcodeStatus: newStatus,
					categoryStatus: newStatus ? false : prevFilters.categoryStatus,
					brandStatus: newStatus ? false : prevFilters.brandStatus,
				};
			} else if (filterName === 'category' || filterName === 'brand') {
				return {
					...prevFilters,
					[`${filterName}Status`]: newStatus,
					barcodeStatus: newStatus ? false : prevFilters.barcodeStatus,
				};
			}

			return {
				...prevFilters,
				[`${filterName}Status`]: newStatus,
			};
		});
	};

	useEffect(() => {
		if (filters.dateStatus || filters.brandStatus || filters.categoryStatus || filters.barcodeStatus) {
			setFilters((prevFilters) => ({
				...prevFilters,
				onlineOrders: true,
				offlineOrders: true,
			}));
		}
	}, [filters.dateStatus, filters.brandStatus, filters.categoryStatus, filters.barcodeStatus]);

	const toggleAllFilters = () => {
		const newStatus = !filters.dateStatus; // Assuming all filters have the same initial status
		setFilters((prevFilters) => ({
			...prevFilters,
			dateStatus: newStatus,
			brandStatus: newStatus,
			categoryStatus: newStatus,
			barcodeStatus: newStatus,
			onlineOrders: newStatus,
			offlineOrders: newStatus,
			active: newStatus,
		}));
	};

	const toggleActiveStatus = () => {
		setFilters((prevFilters) => ({
			...prevFilters,
			active: (prevFilters.active + 1) % 3,
		}));
	};

	const renderInputGroup = (name, label, inputType, options = null) => {
		const categoryLabel = name === 'category' && filters.brand.label !== 'All Brands' ? `Categories for ${filters.brand.label}` : label;

		return (
			<Col
				md={6}
				className="mb-3">
				<Form.Group controlId={name}>
					<Form.Label>{name === 'category' ? categoryLabel : label}</Form.Label>
					<InputGroup>
						<Dropdown
							onSelect={(eventKey, event) => handleComparisonChange(eventKey, event, name)}
							disabled={!filters[`${name}Status`]}>
							<Dropdown.Toggle
								variant="outline-secondary"
								id={`dropdown-${name}`}
								size="sm"
								className="col-6 col-md-3"
								disabled={!filters[`${name}Status`]}>
								{filters[`${name}Comparison`] === '='
									? 'Equal to'
									: filters[`${name}Comparison`] === '>'
									? 'Greater Than'
									: filters[`${name}Comparison`] === '<'
									? 'Less Than'
									: 'Not Equal to'}
							</Dropdown.Toggle>
							<Dropdown.Menu>
								{inputType === 'select' || inputType === 'barcode' ? (
									<>
										<Dropdown.Item
											key="equals"
											eventKey="Equal to">
											Equal to
										</Dropdown.Item>
										<Dropdown.Item
											key="notEquals"
											eventKey="Not Equal to">
											Not Equal to
										</Dropdown.Item>
									</>
								) : (
									<>
										<Dropdown.Item
											key="lessThan"
											eventKey="Less Than">
											Less Than
										</Dropdown.Item>
										<Dropdown.Item
											key="greaterThan"
											eventKey="Greater Than">
											Greater Than
										</Dropdown.Item>
									</>
								)}
							</Dropdown.Menu>
						</Dropdown>
						{inputType === 'select' ? (
							<CustomSelect
								options={options}
								defaultValue={{ value: 0, label: `All ${label}s` }}
								value={filters[name]}
								onChange={(value) => {
									setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
									if (name === 'brand') {
										onBrandChange(value.value);
									}
								}}
								className="flex-grow-1"
								isDisabled={!filters[`${name}Status`]}
							/>
						) : (
							<Form.Control
								type={inputType}
								name={name}
								value={filters[name]}
								onChange={handleInputChange}
								placeholder={`Enter ${label.toLowerCase()}`}
								className=""
								size="sm"
								disabled={!filters[`${name}Status`]}
							/>
						)}
						<ToggleButton
							id={`toggle-${name}`}
							type="checkbox"
							variant={filters[`${name}Status`] ? 'success' : 'danger'}
							checked={filters[`${name}Status`]}
							value="1"
							onChange={() => toggleFilter(name)}
							size="sm"
							className="w-5">
							<span className="visually-hidden">{filters[`${name}Status`] ? 'Enabled' : 'Disabled'}</span>
						</ToggleButton>
					</InputGroup>
				</Form.Group>
			</Col>
		);
	};

	useEffect(() => {
		console.log(categories);
	}, [categories]);

	return (
		<Form
			onSubmit={handleSubmit}
			className="mb-4">
			<fieldset disabled={isDisabled}>
				<Row>
					{renderInputGroup('date', 'Date', 'date')}
					{renderInputGroup('region', 'City', 'select', regions)}
					{renderInputGroup('brand', 'Brand', 'select', brands)}
					{renderInputGroup('total', 'Total', 'number')}
					{renderInputGroup('category', 'Category', 'select', categories)}
					{renderInputGroup('bonusCredits', 'Bonus', 'number')}
					{renderInputGroup('barcode', 'Barcode', 'barcode')}

					<Col
						md={6}
						className="mb-3">
						<Form.Group controlId="otherFilters">
							<Form.Label>
								Other Filters <span className="text-danger">You must select at least one order type</span>
							</Form.Label>
							<ButtonGroup className="mb-2 d-flex">
								{['onlineOrders', 'offlineOrders'].map((radio, idx) => (
									<ToggleButton
										key={idx}
										id={`radio-${idx}`}
										type="checkbox"
										variant="outline-primary"
										name={radio}
										value="1"
										size="sm"
										checked={filters[radio]}
										onChange={handleInputChange}>
										{radio === 'onlineOrders' ? 'Online Orders' : 'Offline Orders'}
									</ToggleButton>
								))}
								<ToggleButton
									id="toggle-active"
									type="button"
									variant={filters.active === 2 ? 'success' : filters.active === 1 ? 'warning' : 'danger'}
									onClick={toggleActiveStatus}
									size="sm">
									{filters.active === 2 ? 'Active Customers' : filters.active === 1 ? 'All Customers' : 'Not Active Customers'}
								</ToggleButton>
							</ButtonGroup>
						</Form.Group>
					</Col>
				</Row>
				<Button
					type="button"
					onClick={toggleAllFilters}
					className="mt-3 me-3">
					Toggle All Filters
				</Button>
				<Button
					type="submit"
					className="mt-3">
					Apply Filters
				</Button>
			</fieldset>
		</Form>
	);
};
